import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { EmptyCard, DocumentCard } from "../../components";
// import DocumentCard from "./DocumentCard";

const DocumentList = ({ data }) => {
  return (
    <Fragment>
      <div className="sidebar-content">
        <div className="row">
          {data ? (
            data?.map((item) => (
              <Link
                to={`/authoring/${item.doc_id === 1 ? "tree" : "model"}/${item.prd_ln}/${item.start_id}/${item.id}`}
                key={`link-${item.id}-${item.id}`}
                className="text-decoration-none display-block"
              >
                <div className="col-12 col-sm-12 col-xs-12 mb-2 d-flex">
                  <DocumentCard {...item} />
                </div>
              </Link>
            ))
          ) : (
            <EmptyCard
              title="Document not found!"
              body="Sorry, the requested documents could not be found. Please check back later or contact support for assistance."
              className="sticky-top"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default DocumentList;
