import _isEmpty from "lodash/isEmpty";

import { useDispatch, useSelector } from "react-redux";
import { getDocumentList } from "../store/actions/authoring.actions";
import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { DeploymentListComponent, Loading } from "../components";
import { deployTreesData } from "../store/actions/deployment.actions";

const Deployment = () => {
  const dispatch = useDispatch();
  const { documentList } = useSelector((state) => state.authoring);

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedItems, setSelectedItems] = useState({
    draft: [],
    staging: [],
    production: [],
  });

  useEffect(() => {
    if (_isEmpty(documentList)) dispatch(getDocumentList());
    setData(documentList);
  }, [dispatch, documentList]);

  const handleCheckboxChange = (deployStatus, item) => {
    setSelectedItems((prevState) => {
      const updatedItems = prevState[deployStatus].some((i) => i.id === item.id)
        ? prevState[deployStatus].filter((i) => i.id !== item.id)
        : [...prevState[deployStatus], item];
      return { ...prevState, [deployStatus]: updatedItems };
    });
  };

  const handleSubmit = (deployStatus) => {
    const envMap = {
      draft: "staging",
      staging: "production",
      production: "draft",
    };

    setIsLoading(true);
    dispatch(
      deployTreesData({
        query: { env: envMap[deployStatus] },
        body: selectedItems[deployStatus],
      })
    ).then(() => {
      setIsLoading(false);
    });

    setIsLoading(true);

    setTimeout(() => {
      dispatch(getDocumentList()).then(() => {
        setIsLoading(false);
      });
    }, 3000);
  };

  const submitBtnTxt = (status) => {
    switch (status) {
      case "draft":
        return "staging";
      case "staging":
        return "production";

      case "production":
        return "";

      default:
        return "";
    }
  };

  return (
    <div className="wrapper">
      <div className="container mx-auto mx-5 p-5">
        <Container>
          {isLoading ? (
            <Loading isIconOnly={true} />
          ) : (
            <Row>
              {["draft", "staging", "production"].map((status) => (
                <>
                  <Col key={status}>
                    <h3>{status.charAt(0).toUpperCase() + status.slice(1)}</h3>
                    <DeploymentListComponent
                      items={data.filter(
                        (item) => item.deploy_status === status
                      )}
                      deployStatus={status}
                      onCheckboxChange={handleCheckboxChange}
                    />

                    {submitBtnTxt(status) !== "" && (
                      <Button
                        className="mt-2"
                        variant="primary"
                        onClick={() => handleSubmit(status)}
                      >
                        Move to {submitBtnTxt(status)}
                      </Button>
                    )}
                  </Col>
                </>
              ))}
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
};

export default Deployment;
