import React, { Fragment, useEffect, useState } from "react";
import {
  ContextMenu,
  ContextMenuItem,
  ContextMenuTrigger,
} from "rctx-contextmenu";
import { Button, Collapse, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading, SVGComponent } from "..";
import { useAlert } from "../../context/AlertContext";
import { useModal } from "../../context/ModalContext";
import {
  addNodeData,
  copyNodeAction,
  linkNodeAction,
  linkNodeData,
  copyNodeData,
  deleteNodeAction,
} from "../../store/actions/authoring.actions";
import { authoring, common } from "../../utills/static-text";
import { renderHtmlContent } from "../../utills/utills";

// import { useDocumentLock } from "../../context/DocumentLockContext";

const TreeNode = ({ node, isDocumentLocked }) => {
  const { prdLn, startId, treeId, type, nodeId, nodeQuesId } = useParams();
  const [ctxMenu, setCtxMenu] = useState([]);
  const [formAction, setFormAction] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [inputOptionText, setInputOptionText] = useState("");
  const [inputQuestionText, setInputQuestionText] = useState("");
  const [inputSolutionText, setInputSolutionText] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const {
    copyNode,
    linkNode,
    removeNode = null,
    addNode = null,
  } = useSelector((state) => state.authoring);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const showModal = useModal();
  // const { isDocumentLocked, toggleDocumentLock, setDocumentLock } =
  //   useDocumentLock();

  // useEffect
  useEffect(() => {
    console.log("isDocumentLocked :::", isDocumentLocked);
    setCtxMenu([
      ...(node.ques_type === "question"
        ? [
            {
              item: "Add Child Question",
              action: "add_child_question",
              hadBorderBottom: false,
              disabled: isDocumentLocked,
            },
          ]
        : []),
      ...(node.ques_type === "question"
        ? [
            {
              item: "Add Child Solution",
              action: "add_child_solution",
              hadBorderBottom: true,
              disabled: isDocumentLocked,
            },
          ]
        : []),
      {
        item: "Edit",
        action: "edit",
        hadBorderBottom: false,
        disabled: !isDocumentLocked,
      },
      ...(node.ques_type === "question" || node.ques_type === "solution"
        ? [
            {
              item: "Copy",
              action: "copy",
              hadBorderBottom: false,
              disabled: isDocumentLocked,
            },
          ]
        : []),
      ...(node.ques_type === "question"
        ? [
            {
              item: "Paste",
              action: "paste",
              hadBorderBottom: true,
              disabled: isDocumentLocked,
            },
          ]
        : []),
      {
        item: "Delete",
        action: "delete",
        hadBorderBottom: false,
        disabled: isDocumentLocked,
      },
      ...(node.ques_type === "question"
        ? [
            {
              item: "Copy Node To Link",
              action: "copyLink",
              hadBorderBottom: false,
              disabled: isDocumentLocked,
            },
            {
              item: "Link Node",
              action: "link",
              hadBorderBottom: false,
              disabled: isDocumentLocked,
            },
          ]
        : []),
    ]);
  }, [node, isDocumentLocked]);

  // Methods
  const resetForm = () => {
    setInputOptionText("");
    setInputQuestionText("");
    setInputSolutionText("");
    setFormAction("");
    setShow(false);
  };

  const setIcon = (nodeType, id) => {
    switch (nodeType) {
      case "question":
        return (
          <span className="svg-question me-2">
            <SVGComponent.Question />
          </span>
        );
      case "solution":
        return (
          <span className="svg-success me-2">
            <SVGComponent.Solution />
          </span>
        );
      default:
        return (
          <span className="svg-dark me-2">
            <SVGComponent.Option />
          </span>
        );
    }
  };

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleFormSubmit = () => {
    const errors = {};
    let hasError = false;

    // Validate inputOptionText
    if (!inputOptionText.trim()) {
      errors.inputOptionText = "Option text is required.";
      hasError = true;
    }

    // Validate inputQuestionText or inputSolutionText based on the formAction
    if (formAction === "add_child_question") {
      if (!inputQuestionText.trim()) {
        errors.inputQuestionText = "Question text is required.";
        hasError = true;
      }
    } else {
      if (!inputSolutionText.trim()) {
        errors.inputSolutionText = "Solution text is required.";
        hasError = true;
      }
    }

    // Update formErrors state with validation errors
    setFormErrors(errors);

    // If there are errors, stop form submission
    if (hasError) {
      return;
    }

    const body =
      formAction === "add_child_question"
        ? {
            type: "question/option",
            ques_raw_content: inputQuestionText,
            option_raw_content: inputOptionText,
            start_id: node.id,
          }
        : {
            type: "solution/option",
            ques_raw_content: inputSolutionText, // TODO: @ravige the key is wrong
            option_raw_content: inputOptionText,
            start_id: node.id,
          };

    setIsLoading(true);
    dispatch(
      addNodeData({
        query: { prd_ln: prdLn, id: treeId },
        body,
      })
    ).then(() => {
      setIsLoading(false);
      showAlert(addNode ? addNode?.message : "node is added!", "success");
      navigate("/authoring");
      return;
    });
    resetForm();
  };

  const handleCloseModal = () => {
    resetForm();
  };

  const handleCloseDeleteModal = () => {
    showModal(null);
  };

  const handleDeleteNode = () => {
    setIsLoading(true);
    // Doomsday for node
    dispatch(
      deleteNodeAction({
        query: {
          ques_type: node.ques_type,
          id: node._id,
        },
      })
    ).then(() => {
      setIsLoading(false);
      showAlert(removeNode ? removeNode?.message : "node is deleted!");
      navigate("/authoring");
    });
    showModal(null);
  };

  const handelContextMenu = (node, actions) => {
    let successMessage = "";
    switch (actions) {
      case "add_child_question":
      case "add_child_solution":
        setFormAction(actions);
        setShow(true);
        break;

      case "copy":
        dispatch(copyNodeAction(node));
        successMessage = `${node.ques_type} is copied!`;
        break;

      case "edit":
        // toggleDocumentLock();
        successMessage = `${node.ques_type} is edited!`;
        break;

      case "paste":
        if (node.id === copyNode.id) {
          showAlert("Can't paste in the same node.");
        } else {
          console.log("node paste ::", node, copyNode);
          dispatch(
            copyNodeData({
              query: { prd_ln: prdLn, id: treeId },
              body: { start_id: node.id, raw_content: copyNode.raw_content },
            })
          );
          dispatch(copyNodeAction({}));
          successMessage = `${node.ques_type} is pasted!`;
        }
        break;

      case "delete":
        showModal({
          title: "Are you sure?",
          body: "Are you sure you want to delete node?",
          footer: (
            <div>
              <Button
                variant="primary"
                className="me-2"
                onClick={handleCloseDeleteModal}
              >
                {common.btn_cancel}
              </Button>
              <Button
                variant="primary"
                className="me-2"
                onClick={handleDeleteNode}
              >
                {common.btn_ok}
              </Button>
            </div>
          ),
        });
        break;

      case "copyLink":
        dispatch(linkNodeAction(node));
        successMessage = `${node.ques_type} is copied!`;
        break;

      case "link":
        if (node.id === linkNode.id) {
          showAlert("Can't link in the same node.");
        } else {
          dispatch(
            linkNodeData({
              query: { prd_ln: prdLn, id: treeId },
              body: { start_id: node.id, end_id: linkNode.id },
            })
          );
          dispatch(linkNodeAction({}));
          successMessage = `${node.ques_type} is linked!`;
        }
        break;

      default:
        break;
    }

    if (successMessage) {
      showAlert(successMessage, "success");
    }
  };

  return (
    <div className="treeview tree-node">
      {/* ContextMenuTrigger */}
      <ContextMenuTrigger
        id={`context-menu-${node._id}`}
        className={`context-menu-${node._id}`}
      >
        <Link
          onClick={handleExpand}
          aria-controls={`collapse-item-${node._id}`}
          aria-expanded={isExpanded}
          to={`/authoring/tree/${prdLn}/${startId}/${treeId}/edit-node/${node.ques_type}/${node.id}/${node._id}`}
          className={`${node.ques_type} node-toggle ${
            nodeId === node.id.toString() &&
            type === node.ques_type &&
            nodeQuesId === node._id
              ? " active"
              : ""
          }`}
        >
          {setIcon(node.ques_type, node.id.toString())}{" "}
          <span
            className="text-desc"
            dangerouslySetInnerHTML={renderHtmlContent(node.name)}
          />
        </Link>
      </ContextMenuTrigger>

      {/* ContextMenu */}
      <ContextMenu id={`context-menu-${node._id}`}>
        {ctxMenu.map((ctx, index) => (
          <Fragment key={`ctx-${index}-${node._id}`}>
            <ContextMenuItem
              onClick={() => handelContextMenu(node, ctx.action)}
              className={ctx.hadBorderBottom ? "border-bottom" : ""}
            >
              {ctx.item}
            </ContextMenuItem>
          </Fragment>
        ))}
      </ContextMenu>

      {/* Collapse */}
      <Collapse id={`collapse-item-${node._id}`} in={isExpanded}>
        <ul className="child-nodes" style={{ listStyleType: "none" }}>
          {node.children &&
            node.children.map((childNode, index) => (
              <li key={`child-node-${prdLn}-${startId}-${treeId}-${index}`}>
                <TreeNode
                  node={childNode}
                  isDocumentLocked={isDocumentLocked}
                />
              </li>
            ))}
        </ul>
      </Collapse>

      {/* Modal */}
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {formAction === "add_child_question"
              ? authoring.newNode.add_qo
              : authoring.newNode.add_os}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Loading isIconOnly={true} />
          ) : (
            <Form onSubmit={handleFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>{common.option_text}</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  type="text"
                  placeholder={common.name}
                  value={inputOptionText}
                  onChange={(e) => {
                    setInputOptionText(e.target.value);
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      inputOptionText: "",
                    }));
                  }}
                />
                {formErrors.inputOptionText && (
                  <p className="text-danger">{formErrors.inputOptionText}</p>
                )}
              </Form.Group>

              {formAction === "add_child_question" ? (
                <Form.Group className="mb-3">
                  <Form.Label>{common.question_text}</Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    type="text"
                    placeholder={common.enter_text}
                    value={inputQuestionText}
                    onChange={(e) => {
                      setInputQuestionText(e.target.value);
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        inputQuestionText: "",
                      }));
                    }}
                  />
                  {formErrors.inputQuestionText && (
                    <p className="text-danger">
                      {formErrors.inputQuestionText}
                    </p>
                  )}
                </Form.Group>
              ) : (
                <Form.Group className="mb-3">
                  <Form.Label>{common.solution_text}</Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    type="text"
                    placeholder={common.enter_text}
                    value={inputSolutionText}
                    onChange={(e) => {
                      setInputSolutionText(e.target.value);
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        inputSolutionText: "",
                      }));
                    }}
                  />
                  {formErrors.inputSolutionText && (
                    <p className="text-danger">
                      {formErrors.inputSolutionText}
                    </p>
                  )}
                </Form.Group>
              )}
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleFormSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TreeNode;
