import React, { Fragment, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Loading } from "..";
import { createDocumentData } from "../../store/actions/authoring.actions";
import { authoring, common } from "../../utills/static-text";

// Validate column titles
const expectedColumnTitles = String(
  process.env.REACT_APP_MODEL_LIST_DOCUMENT_MANDATORY_FIELDS || ""
).split(",");

const NewDocumentForm = ({ filterByConf }) => {
  const [selectedEquipment, setSelectedEquipment] = useState("");
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [notes, setNotes] = useState("");
  const [file, setFile] = useState(null);
  const [isValidCsv, setIsValidCsv] = useState(true);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleEquipmentSelect = (equipment) => {
    console.log("equipment ", equipment);
    setSelectedEquipment(equipment);
  };

  const handleDocumentTypeSelect = (type) => {
    setSelectedDocumentType(type);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Validate CSV file
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target.result;
        const lines = csvData.split("\n");
        const columnTitles = lines[0].trim().split(",");

        // Validate column titles
        // const expectedColumnTitles = [
        //   "MODEL_NO",
        //   "PRODUCT_LINE_CODE",
        //   "PRODUCT_TYPE",
        //   "PRODUCT_SUBTYPE",
        //   "MD_TYPE",
        // ];

        const isValid = expectedColumnTitles.every((title) =>
          columnTitles.includes(title)
        );

        setIsValidCsv(isValid);
      };

      reader.readAsText(selectedFile);
    }
  };

  const validateForm = () => {
    let errors = {};

    console.log("selectedEquipment ::", selectedEquipment);

    // Check if prd_ln is empty
    if (!selectedEquipment) {
      errors.prd_ln = "Please select target equipment";
    }

    // Check if doc_id is empty
    if (!selectedDocumentType) {
      errors.doc_id = "Please select document type";
    }

    // Check if name is empty
    if (!documentName) {
      errors.name = "Please enter document name";
    }

    // Clear error messages if all fields are filled
    if (
      selectedEquipment &&
      selectedEquipment.prd_ln &&
      selectedDocumentType &&
      documentName
    ) {
      setErrors({});
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = validateForm();

    // If there are errors, set the state and return
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Your form submission logic here
    const payload = {
      prd_ln: selectedEquipment,
      doc_id: selectedDocumentType,
      name: documentName,
      notes: notes,
      file: file,
    };

    setIsLoading(true);
    dispatch(
      createDocumentData({
        body: payload,
        method: "POST",
      })
    ).then(() => setIsLoading(false));

    // Clear any previous errors
    setErrors({});
  };

  const handleDownloadCsv = () => {
    // Create a blob object from the React app file
    const blob = new Blob(
      [
        "MODEL_NO,PRODUCT_LINE_CODE,PRODUCT_TYPE,PRODUCT_SUBTYPE,MD_TYPE,MD_SUBTYPE,,,,,",
      ],
      { type: "text/csv" }
    );

    // Create an object URL from the blob object
    const objectUrl = window.URL.createObjectURL(blob);

    // Create a hidden anchor element
    const anchor = document.createElement("a");
    anchor.href = objectUrl;
    anchor.download = "sample_model_list.csv";
    anchor.click();

    // Revoke the object URL
    URL.revokeObjectURL(objectUrl);
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loading isIconOnly={true} />
      ) : (
        <Fragment>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>{authoring.newDocument.target_equipment}</Form.Label>
              <span className="text-danger">*</span>
              {/* <Dropdown>
              <Dropdown.Toggle
                variant="outline"
                className="border"
                id="dropdown-equipment"
              >
                {selectedEquipment
                  ? selectedEquipment.name
                  : authoring.newDocument.select_target_equipment}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {filterByConf.map((equipment) => (
                  <Dropdown.Item
                    key={`item-${equipment.id}`}
                    onClick={() => handleEquipmentSelect(equipment)}
                  >
                    {equipment.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}

              <Form.Select
                onChange={(e) => handleEquipmentSelect(e.target.value)}
              >
                <option value="">
                  {selectedEquipment
                    ? selectedEquipment.name
                    : authoring.newDocument.select_target_equipment}
                </option>

                {filterByConf.map((equipment) => (
                  <option
                    key={`item-${
                      equipment.name.replace(/\s/g, "") ||
                      Math.floor(Math.random() * (100 - 99 + 1)) + 99
                    }`}
                    value={equipment.prd_ln}
                  >
                    {equipment.name}
                  </option>
                ))}
              </Form.Select>

              {errors.prd_ln && <p className="text-danger">{errors.prd_ln}</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{common.document_type}</Form.Label>
              <span className="text-danger">*</span>
              <Form.Select
                onChange={(e) => handleDocumentTypeSelect(e.target.value)}
              >
                <option value="">
                  {authoring.newDocument.select_document_type}
                </option>
                <option value="1">{common.decision_tree}</option>
                <option value="2">{common.model_list}</option>
              </Form.Select>
              {errors.doc_id && <p className="text-danger">{errors.doc_id}</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{authoring.newDocument.new_document_name}</Form.Label>
              <span className="text-danger">*</span>
              <Form.Control
                type="text"
                placeholder={common.name}
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
              />
              {errors.name && <p className="text-danger">{errors.name}</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{common.notes}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={common.notes}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Group>

            {selectedDocumentType === "2" && (
              <Form.Group className="mb-3">
                <Form.Control
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                />
                {!isValidCsv && (
                  <p className="text-danger">CSV file columns are not valid.</p>
                )}
                {isValidCsv && file && (
                  <p className="text-success">
                    Selected{" "}
                    <em>
                      <u>"{file.name}"</u>
                    </em>{" "}
                    is validatd with columns.
                  </p>
                )}
              </Form.Group>
            )}

            {selectedDocumentType === "2" && (
              <Card className="mb-3">
                <Card.Body>
                  <div>
                    <span className="text-danger text-uppercase">
                      {common.notes}
                    </span>
                    {": "}
                    The text CSV format files strictly follow the structure
                    shown below mandatory<span className="text-danger">
                      *
                    </span>{" "}
                    fields:
                    <div className="fs-smaller ff-monospace">
                      MODEL_NO, PRODUCT_LINE_CODE, PRODUCT_TYPE, PRODUCT_SUBTYPE
                    </div>
                  </div>
                  <div className="overflow-auto">
                    <table className="table table-bordered fs-smaller">
                      <thead>
                        <tr>
                          <th>
                            MODEL_NO<span className="text-danger">*</span>
                          </th>
                          <th>
                            PRODUCT_LINE_CODE
                            <span className="text-danger">*</span>
                          </th>
                          <th>
                            PRODUCT_TYPE<span className="text-danger">*</span>
                          </th>
                          <th>
                            PRODUCT_SUBTYPE
                            <span className="text-danger">*</span>
                          </th>
                          <th>MD_TYPE</th>
                          <th>MD_SUBTYPE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Button
                    variant="outline-flat"
                    onClick={handleDownloadCsv}
                    className="btn btn-link"
                  >
                    <i className="fa-solid fa-file-csv"></i> Download sample CSV
                    file
                  </Button>
                </Card.Body>
              </Card>
            )}
            <div className="text-end">
              <Button variant="outline-secondary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NewDocumentForm;
