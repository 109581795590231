import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AppRoutes from "./routes";
import { AlertProvider } from "./context/AlertContext";
import { ModalProvider } from "./context/ModalContext";
import { DocumentLockProvider } from "./context/DocumentLockContext";
function App() {
  return (
    <DocumentLockProvider>
      <ModalProvider>
        <AlertProvider>
          <AppRoutes />
        </AlertProvider>
      </ModalProvider>
    </DocumentLockProvider>
  );
}

export default App;
