import { Fragment } from "react";
import { Table } from "react-bootstrap";
import { DateFormatter } from "..";

const ConfigurationDetails = ({ product, problemCategory }) => {
  return (
    <>
      {product && (
        <Fragment>
          <h4>
            <i className="fas fa-layer-group" aria-hidden="true"></i>{" "}
            {product.name}
          </h4>
          <Table striped bordered hover>
            <tbody>
              {Object.entries(product).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>
                    {key === "created_at" || key === "updated_at"
                      ? DateFormatter(new Date(value), "d MMM yyyy, h:mm a")
                      : value || "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {problemCategory.length > 0 && (
            <div>
              <h4>
                <i className="fas fa-layer-group" aria-hidden="true"></i>{" "}
                {product.name} Problem Categories
              </h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Problem</th>
                    <th>Is Consumer</th>
                    <th>Is Agent</th>
                  </tr>
                </thead>
                <tbody>
                  {problemCategory.map((category, index) => (
                    <tr key={index}>
                      <td>{category.problem}</td>
                      <td>{category.isconsumer ? "Yes" : "No"}</td>
                      <td>{category.isagent ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Fragment>
      )}
    </>
  );
};

export default ConfigurationDetails;
