import { OverlayTrigger } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { DateFormatter, GETooltip, SVGComponent } from "..";
import { Fragment } from "react";

const DocumentCard = (item) => {
  const { treeId } = useParams();
  const isActive = treeId === item.id;

  const cssClassName = () => {
    let bgColor = "bg-light text-dark";
    let svgColor = "svg-dark";
    let cardTitle =
      item.deploy_status !== "draft" && item.deploy_status !== ""
        ? `Available on ${item.deploy_status}`
        : "Needs review";

    if (item.islocked) {
      bgColor = "bg-document-lock text-white";
      svgColor = "svg-light";
    }
    if (item.deploy_status === "production") {
      bgColor = "bg-success text-white";
      svgColor = "svg-light";
    }
    if (item.deploy_status === "staging") {
      bgColor = "bg-warning text-dark";
      svgColor = "svg-dark";
    }
    return {
      card: `card ${
        item?.deploy_status !== "" && !item?.islocked ? item?.deploy_status : ""
      } card-document flex-fill${isActive ? " active" : ""} ${
        item.islocked ? " locked" : ""
      } ${item.isdeleted ? " deleted" : ""}`,
      cardTitle,
      roundedCircle: `rounded-circle d-flex align-items-center ${svgColor} justify-content-center ${bgColor}`,
      badge: `badge text-capitalize fw-light ${bgColor}`,
    };
  };

  const OverlayTriggerMsg = ({ children, message = "" }) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={GETooltip(
          item.islocked
            ? { msg: `${message} Locked` }
            : item.doc_id === 1
            ? { msg: `${message} Decision Tree` }
            : { msg: `${message} Model List` }
        )}
      >
        {children}
      </OverlayTrigger>
    );
  };

  return (
    <div className={cssClassName().card} title={cssClassName().cardTitle}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="icon-wrapper flex-1">
            <div className={cssClassName().roundedCircle}>
              <OverlayTriggerMsg>
                {item.doc_id === 1 ? (
                  <SVGComponent.DecisionTree />
                ) : (
                  <SVGComponent.ModelList />
                )}
              </OverlayTriggerMsg>
            </div>
          </div>
          <OverlayTrigger
            placement="top"
            overlay={GETooltip({
              msg: item.name,
            })}
          >
            <h5 className="card-title mb-0 flex-fill px-2 text-truncate">
              {item.name}
            </h5>
          </OverlayTrigger>
          {isActive &&
            (item.islocked ? (
              <span className="svg-danger">
                <SVGComponent.Lock />
              </span>
            ) : (
              <i
                className="fas fa-external-link ml-3"
                data-toggle="tooltip"
                data-placement="top"
                title="Opened"
              ></i>
            ))}
        </div>
        <div className="card-text d-flex justify-content-start align-items-center">
          {item.islocked && item.notes && (
            <span className="me-2">
              <OverlayTriggerMsg
                message={item.doc_id === 1 ? "Decision Tree" : "Model List"}
              >
                <span className={item.islocked ? "svg-document-lock" : ""}>
                  {item.doc_id === 1 ? (
                    <SVGComponent.DecisionTree />
                  ) : (
                    <SVGComponent.ModelList />
                  )}
                </span>
              </OverlayTriggerMsg>
            </span>
          )}
          {item.notes && (
            <div className="fs-smaller">
              <span className="flex-fill">{item.notes}</span>
            </div>
          )}
        </div>
        <div className="text-muted fw-lighter fs-small right">
          {item?.updated_at ? (
            <Fragment>
              <i className="fa-solid fa-edit" title="Updated at"></i>{" "}
              {DateFormatter(new Date(item.updated_at), "d MMM yyyy, h:mm a")}{" "} Kedar
              {item.updated_by}
            </Fragment> 
          ) : (
            <Fragment>
              <i className="fa-solid fa-pencil" title="Created at"></i>{" "}
              {DateFormatter(new Date(item.created_at), "d MMM yyyy, h:mm a")}
            </Fragment>
          )}
          <br />
        </div>
      </div>

      <div className="card-footer">
        <div>
          <span className="badge bg-dark text-white fw-lighter me-2">
            {item.conf_name}
          </span>

          {/* <span className="me-2">
            <OverlayTriggerMsg message="Decision Tree">
              <Fragment>
                <SVGComponent.DecisionTree />{" "}
                <span className={cssClassName().badge}>
                  {item.decision_tree_count || 0}
                </span>
              </Fragment>
            </OverlayTriggerMsg>
          </span>

          <span className="me-2">
            <OverlayTriggerMsg message="Model List">
              <Fragment>
                <SVGComponent.ModelList />{" "}
                <span className={cssClassName().badge}>
                  {item.models_list_count || 0}
                </span>
              </Fragment>
            </OverlayTriggerMsg>
          </span> */}

          <OverlayTriggerMsg message="Deployment status for">
            <span className={cssClassName().badge}>
              {item.deploy_status || "Draft"}
            </span>
          </OverlayTriggerMsg>
        </div>
        {item.islocked && (
          <div className="mt-2">
            <OverlayTriggerMsg message={item.locked_by}>
              <div className="locked-by text-truncate">
                <SVGComponent.Lock /> by: {item.locked_by}
              </div>
            </OverlayTriggerMsg>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentCard;
