export const BASE_URL = `${process.env.REACT_APP_API_ORIGIN}/v1/api`;

export const ROUTES = {
  DASHBOARD: {
    PATH: "/",
    TITLE: "dashboard",
    NAME: "Dashboard",
  },
  DASHBOARD_LOG: {
    PATH: "/dashboard/:environment",
    TITLE: "auditTrail",
    NAME: "Audit Trail",
  },
  DEPLOY: {
    PATH: "/deployment",
    TITLE: "deployment",
    NAME: "Deployment",
  },
  AUTHORING: {
    PATH: "/authoring",
    TITLE: "authoring",
    NAME: "Authoring",
  },
  AUTHORING_DOCUMENT: {
    PATH: "/authoring/:docType/:prdLn/:startId/:treeId",
    TITLE: "decision-authoring",
    NAME: "Authoring",
  },
  AUTHORING_DOCUMENT_EDIT: {
    PATH: "/authoring/:docType/:prdLn/:startId/:treeId/edit-node/:type/:nodeId/:nodeQuesId",
    TITLE: "decision-authoring",
    NAME: "Authoring",
  },
  // AUTHORING_MODEL: {
  //   PATH: "/authoring/model/:prdLn/:startId/:treeId",
  //   TITLE: "decision-authoring",
  //   NAME: "Authoring",
  // },
  // AUTHORING_MODEL_EDIT: {
  //   PATH: "/authoring/model/:prdLn/:startId/:treeId/edit-node/:type/:nodeId/:nodeQuesId",
  //   TITLE: "decision-authoring",
  //   NAME: "Authoring",
  // },
  CONFIGURATION: {
    PATH: "/configuration",
    TITLE: "configuration",
    NAME: "Configuration",
  },
  CONFIGURATION_VIEW: {
    PATH: "configuration/:prdLn/:confId",
    TITLE: "edit-configuration",
    NAME: "Configuration",
  },
  CONFIGURATION_EDIT: {
    PATH: "configuration/:prdLn/:confId/edit",
    TITLE: "edit-configuration",
    NAME: "Configuration",
  },
};
