import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({ value, onChange, errors, height = 150, disabled }) => {
  const editorRef = useRef(null);
  return (
    <>
      <Editor
        initialValue={value}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onChange={(e) => {
          onChange && onChange(e.target.getContent());
        }}
        disabled={disabled}
        apiKey="8rk71jnaftrscx1yyz7ruiw2mbk8q3dy92d62il26smop4n4"
        init={{
          height,
          menubar: false,
          plugins:
            "advlist autolink lists link image charmap print preview anchor | visualblocks code fullscreen | insertdatetime media table paste code wordcount",
          toolbar:
            "bold italic underline | spellcheckdialog | align lineheight | undo redo | checklist numlist bullist indent outdent | removeformat",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {errors.name && <p className="text-danger">{errors.name}</p>}
    </>
  );
};

export default TextEditor;
