import { Outlet, Navigate } from "react-router-dom";
import { Navbar } from "../components";
const Home = ({ user }) => {
  // if (!user) {
  //   console.log("user not found");
  //   return <Navigate to='/login' />;
  // }
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};
export default Home;
