import _isEmpty from "lodash/isEmpty";
import { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "../../context/AlertContext";

import {
  addProblem,
  deleteProblem,
  editProblem,
  searchProblem,
} from "../../store/actions/configuration.actions";

const FormProblemCategories = ({
  equipmentData,
  prdLn,
  refreshEquipmentData,
}) => {
  const dispatch = useDispatch();
  const { problemSearch } = useSelector((state) => state.configuration);
  const [dataSource, setDataSource] = useState([]);
  const [newRow, setNewRow] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [formValue, setFormValue] = useState({
    problem: "",
    isconsumer: false,
    isagent: false,
  });
  const showAlert = useAlert();

  useEffect(() => {
    setDataSource(equipmentData);
  }, [equipmentData]);

  const handleDelete = (id) => {
    if (id) {
      dispatch(deleteProblem({ prd_ln: prdLn, id: id })).then(() => {
        refreshEquipmentData();
        showAlert("Problem category is deleted!.", "success");
      });
    } else {
      setFormValue({ problem: "", isconsumer: false, isagent: false });
      setEditIndex(null);
    }
  };

  const handleEdit = (index, row) => {
    setEditIndex(index);
    setFormValue({ ...row });
  };

  const handleSave = async (row) => {
    if (!row.problem) return;
    await dispatch(
      searchProblem({
        query: { prdLn: prdLn },
        body: { search_text: row.problem },
      })
    ).then(() => {
      if (problemSearch.length > 0)
        return showAlert("Problem category available with same value.");
    });

    if (!row.id) {
      dispatch(addProblem({ query: { prdLn: prdLn }, body: row }));
    } else {
      dispatch(editProblem({ query: { prdLn: prdLn, id: row.id }, body: row }));
    }
    setEditIndex(null);
    setFormValue({ problem: "", isconsumer: false, isagent: false });
    refreshEquipmentData();
    setNewRow(false);
  };

  const handleAddNewRow = () => {
    setNewRow(true);
    setFormValue({ id: null, problem: "", isconsumer: false, isagent: false });
  };

  const handleCancelAdd = () => {
    setNewRow(false);
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr className="align-middle text-center">
          <th style={{ width: "55%" }} scope="col">
            Problem
          </th>
          <th scope="col">
            Consumer<br></br> Assist
          </th>
          <th scope="col">
            Agent <br></br> Assist
          </th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {!_isEmpty(dataSource) ? (
          dataSource.map((e, i) => (
            <tr key={`${i}-row-count`}>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    className="form-control"
                    value={formValue.problem}
                    onChange={(e) =>
                      setFormValue({ ...formValue, problem: e.target.value })
                    }
                  />
                ) : (
                  e.problem
                )}
              </td>
              <td className="align-middle text-center">
                {editIndex === i ? (
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formValue.isconsumer}
                    value={formValue.isconsumer}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        isconsumer: e.target.checked,
                      })
                    }
                  />
                ) : (
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={e.isconsumer}
                    readOnly
                  />
                )}
              </td>
              <td className="align-middle text-center">
                {editIndex === i ? (
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formValue.isagent}
                    value={formValue.isagent}
                    onChange={(e) =>
                      setFormValue({ ...formValue, isagent: e.target.checked })
                    }
                  />
                ) : (
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={e.isagent}
                    readOnly
                  />
                )}
              </td>
              <td className="align-middle text-center">
                {editIndex === i ? (
                  <Fragment>
                    <i
                      className="fa-solid fa-floppy-disk me-2"
                      onClick={() => handleSave(formValue)}
                    ></i>

                    <i
                      className="fa-solid fa-xmark me-2"
                      aria-hidden="true"
                      onClick={() => handleDelete(null)}
                    ></i>
                  </Fragment>
                ) : (
                  <i
                    className="fa-solid fa-pen-to-square me-2"
                    onClick={() => handleEdit(i, e)}
                  ></i>
                )}

                <i
                  className="fa-solid fa-trash-can"
                  aria-hidden="true"
                  onClick={() => handleDelete(e.id)}
                ></i>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <th colSpan={4}>{"No Data"}</th>
          </tr>
        )}

        {newRow && (
          <tr>
            <td>
              <input
                type="text"
                className="form-control"
                value={formValue.problem}
                onChange={(e) =>
                  setFormValue({ ...formValue, problem: e.target.value })
                }
              />
            </td>
            <td className="align-middle text-center">
              <input
                type="checkbox"
                className="form-check-input"
                checked={formValue.isconsumer}
                value={formValue.isconsumer}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    isconsumer: e.target.checked,
                  })
                }
              />
            </td>
            <td className="align-middle text-center">
              <input
                type="checkbox"
                className="form-check-input"
                checked={formValue.isagent}
                value={formValue.isagent}
                onChange={(e) =>
                  setFormValue({ ...formValue, isagent: e.target.checked })
                }
              />
            </td>
            <td className="align-middle text-center">
              <i
                className="fas fa-save me-2"
                onClick={() => handleSave(formValue)}
              ></i>

              <i className="fa-solid fa-xmark" onClick={handleCancelAdd}></i>
            </td>
          </tr>
        )}

        {!newRow && (
          <tr>
            <td colSpan={4} className="text-center">
              <Button variant="primary" onClick={handleAddNewRow}>
                Add New
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default FormProblemCategories;
