import mapValues from "lodash/mapValues";

const ACTIONS = {
  APP_RESET: null,
  AUTHORING_CONF_FILTER: null,
  AUTHORING_GET_DOCUMENT: null,
  AUTHORING_GET_LIST: null,
  AUTHORING_GET_TREEDATA: null,
  AUTHORING_NEW_DOCUMENT_POST: null,
  AUTHORING_PROBLEM_SEARCH: null,
  COPY_NODE: null,
  LINK_NODE: null,
  EQUIPMENTS_DATA_GET_ID: null,
  EQUIPMENTS_DATA_GET_LIST: null,
  EQUIPMENTS_DATA_PUT_POST: null,
  TREENODE_ADD_POST: null,
  TREENODE_DATA_PUT_POST: null,
  TREENODE_LOCK_ID: null,
  TREENODE_REMOVE: null,
  AUTH_REQUEST: null,
  AUTH_ERROR: null,
  DEPLOY_TREE: null,
  DEPLOY_TREES: null,
  SEARCH_TEXT_POST: null
};

export default mapValues(ACTIONS, (v, k) => k);
