import _isEmpty from "lodash/isEmpty";
import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { TagInput, TextEditor, Loading } from "../../components";
import { useAlert } from "../../context/AlertContext";
import { updateTreeNodeData } from "../../store/actions/authoring.actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const DocumentProperty = ({ documentProperty, isDocumentLocked }) => {
  const {
    prdLn = "",
    // startId = "",
    // treeId = "",
    // type = "",
    // nodeId = "",
    nodeQuesId = "",
  } = useParams();
  const showAlert = useAlert();

  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [displayConfig, setDisplayConfig] = useState({});
  const [formValues, setFormValues] = useState({
    name: "",
    notes: "",
    tags: [],
    parts: [],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (documentProperty) {
      setTimeout(() => setIsLoading(false), 30);
    }
    const quesType = documentProperty?.ques_type;
    const displayConfig = {
      name: true,
      notes: quesType !== "option",
      parts: quesType === "solution",
      tags: true,
      file: false,
    };
    setDisplayConfig(displayConfig);
    setFormValues(documentProperty);
    if(isDocumentLocked) {
      showAlert("Unlock the document to edit the information!", "warning");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentProperty]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setIsLoading(true);
    dispatch(
      updateTreeNodeData({
        query: {
          prd_ln: prdLn,
          id: nodeQuesId,
        },
        body: {
          id: formValues._id,
          ques_type: formValues.ques_type,
          ques_raw_content: formValues.name,
          raw_content: formValues.name,
          start_id: formValues.id,
          notes: formValues.notes,
          parts: formValues.parts,
          tags: formValues.tags,
        },
        method: "PUT",
      })
    ).then(() => {
      setIsLoading(false);
      showAlert("Node is updated!", "success");
    });
  };

  const validateForm = () => {
    let errors = {};
    if (!formValues.name) {
      errors.name = "Please enter document name";
    }
    return errors;
  };

  const handleInputChange = (key, value) => {
    if (key === "tags" && value === "") {
      setFormValues({ ...formValues, [key]: [] });
    } else {
      setFormValues({ ...formValues, [key]: value });
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {isLoading ? (
          <Loading isIconOnly={true} />
        ) : (
          <Fragment>
            <h5 className="mt-2 mb-3 text-capitalize">
              {documentProperty?.ques_type}
            </h5>

            {formValues && (
              <Form onSubmit={handleSubmit}>
                {displayConfig.name && (
                  <Form.Group className="mb-3">
                    <TextEditor
                      value={formValues.name}
                      onChange={(content) => handleInputChange("name", content)}
                      errors={errors}
                      disabled={isDocumentLocked}
                    />
                  </Form.Group>
                )}
                {displayConfig.notes && (
                  <Form.Group className="mb-3">
                    <Form.Label>Notes</Form.Label>
                    <TextEditor
                      value={formValues.notes}
                      onChange={(content) =>
                        handleInputChange("notes", content)
                      }
                      errors={errors}
                      disabled={isDocumentLocked}
                    />
                  </Form.Group>
                )}
                {displayConfig.tags && (
                  <Form.Group className="mb-3">
                    <Form.Label>Tags</Form.Label>
                    <TagInput
                      value={!_isEmpty(formValues.tags) ? formValues?.tags : ""}
                      onChange={(content) =>
                        handleInputChange("tags", content.split(","))
                      }
                      disabled={isDocumentLocked}
                    />
                  </Form.Group>
                )}
                {displayConfig.parts && (
                  <Form.Group className="mb-3">
                    <Form.Label>Parts</Form.Label>
                    <TagInput
                      value={
                        _isEmpty(formValues?.parts)
                          ? formValues?.parts?.join(",")
                          : ""
                      }
                      onChange={(content) =>
                        handleInputChange("parts", content.split(","))
                      }
                      disabled={isDocumentLocked}
                    />
                  </Form.Group>
                )}
                {displayConfig.file && (
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Attachments</Form.Label>
                    <Form.Control type="file" disabled={true} />
                  </Form.Group>
                )}
                <div className="row">
                  <div className="col-12 mt-3 text-end">
                    <button className="btn btn-muted me-2" disabled={isDocumentLocked}>Reset</button>
                    <button type="submit" className="btn btn-primary" disabled={isDocumentLocked}>
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DocumentProperty;
