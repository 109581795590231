import ACTIONS from './types.actions';
import axios from 'axios';

export function userError(error) {
    return { type: ACTIONS.AUTH_ERROR, error };
}

export function userData(data) {
    return { type: ACTIONS.AUTH_REQUEST, data };
}

export function checkAuth() {
    return async dispatch => {
        try {
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_ORIGIN}/authenticate`,
                withCredentials: true
            }).then(response => {
                if (response.data.user) {
                    window.sessionStorage.setItem("session", JSON.stringify(response.data.user));
                    dispatch(userData(response.data.user));
                }
                else {
                    RedirectToLogin();
                }
            }).catch(error => {
                console.log(error, 'SAML');
                RedirectToLogin();
            })

        } catch (error) {
            console.log('authentication error', error);
            dispatch(userError);
        }
    };
};

const RedirectToLogin = () => {
    window.location.replace(`${process.env.REACT_APP_API_ORIGIN}/login`);
}