import _isEmpty from "lodash/isEmpty";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useModal } from "../../context/ModalContext";

import {
  DocumentHeader,
  Loading,
  TreeNode,
  DocumentProperty,
  EmptyDocument,
  SVGComponent,
} from "..";
import {
  getTreeData,
  getDocumentData,
  lockTreeData,
  deployTreeData,
  getSearchTextData,
} from "../../store/actions/authoring.actions";
import { authoring, common } from "../../utills/static-text";
import { renderHtmlContent } from "../../utills/utills";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useAlert } from "../../context/AlertContext";

const Document = ({ onDocumentUpdate }) => {
  const dispatch = useDispatch();
  const showModal = useModal();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const {
    prdLn = "",
    startId = "",
    treeId = "",
    type = "",
    nodeId = "",
    nodeQuesId = "",
    docType = "",
  } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const { treeData, documentData, searchTextData } = useSelector(
    (state) => state.authoring
  );
  const [documentProperty, setDocumentProperty] = useState([]);
  const [idArr, setIdArr] = useState([]);
  const [deploymentOptions, setDeploymentOptions] = useState([]);
  const [btnEditDisable, setBtnEditDisable] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const isDocumentLocked = useRef(false);

  useEffect(() => {
    const findTreeNodeById = (objArray, targetId) => {
      if (objArray) {
        for (let obj of objArray) {
          if (obj.id === targetId && obj?.ques_type === type) {
            return obj;
          }
          if (obj.children && obj.children.length > 0) {
            const foundInChildren = findTreeNodeById(obj.children, targetId);
            if (foundInChildren) {
              return foundInChildren;
            }
          }
        }
      }
      return null;
    };

    const loadQandA = findTreeNodeById(
      treeData,
      type ? Number(nodeId) : Number(treeId)
    );
    setDocumentProperty(loadQandA);
  }, [treeData, type, nodeId, treeId, searching]);

  useEffect(() => {
    let editDisable = !treeData && !documentData;
    setBtnEditDisable(editDisable);
  }, [treeData, documentData]);

  useEffect(() => {
    // Logic to fetch document data
    if (startId === "1") {
      setIdArr([nodeQuesId]);
      dispatch(
        getTreeData({
          prd_ln: prdLn,
          start_id: startId,
          id: treeId,
          docType: docType,
        })
      ).then(() => setIsLoading(false));
    }

    // Logic to fetch tree data
    dispatch(getDocumentData({ prd_ln: prdLn, id: treeId }));
    setIdArr([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prdLn, treeId, isUpdate]);

  useEffect(() => {
    if (
      !idArr.includes(nodeQuesId) &&
      type !== "question" &&
      type !== "solution"
    ) {
      setIdArr([...idArr, nodeQuesId]);
      dispatch(
        getTreeData({
          prd_ln: prdLn,
          start_id: nodeId,
          id: treeId,
          docType: docType,
        })
      ).then(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId, nodeQuesId, type, dispatch, isUpdate]);

  // Logic to set deployment options and show alert
  useEffect(() => {
    if (!documentData) return;

    const deploymentOptionsMap = {
      draft: [
        {
          name: common.staging,
          variant: "Warning",
          disabled: false,
          msg: common.deploy_help_text + common.staging,
        },
      ],
      staging: [
        {
          name: common.production,
          variant: "Success",
          disabled: false,
          msg: common.deploy_help_text + common.production,
        },
      ],
      production: [
        {
          name: common.production,
          variant: "Success",
          disabled: true,
          msg: "Data is up-to-date on " + common.production,
        },
      ],
    };

    const defaultOptions = [
      {
        name: common.staging,
        variant: "Warning",
        disabled: false,
        msg: common.deploy_help_text + common.staging,
      },
    ];

    const deploymentOptions =
      deploymentOptionsMap[documentData.deploy_status] || defaultOptions;

    if (isDocumentLocked.current) {
      showAlert(
        `The document is currently locked by the ${documentData.locked_by}.`,
        "danger"
      );
    }
    // setDocumentLock(documentData.islocked); // document property frequnently change in the view hence it should presnet here
    isDocumentLocked.current = documentData.islocked;

    console.log("first set of isDocumentLocked", isDocumentLocked.current);
    setDeploymentOptions(deploymentOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentData]);

  const handleDocumentIsEdit = () => {
    setIsLoading(true);

    dispatch(
      lockTreeData({
        query: { prd_ln: prdLn, id: treeId, bool: !isDocumentLocked.current },
      })
    ).then(() => {
      dispatch(getDocumentData({ prd_ln: prdLn, id: treeId }));
      onDocumentUpdate(); // called from Authoring to reload the left document list
      setIsLoading(false);
      return;
    });

    isDocumentLocked.current = documentData.islocked;

    // if (!_isEmpty(treeData) && !_isEmpty(documentData)) {
    //   return;
    // }
  };

  const handleCloseModal = () => {
    showModal(null);
    navigate("/authoring");
  };

  const handelDocumentClose = () => {
    if (isDocumentLocked.current) {
      showModal({
        title: "Are you sure?",
        body: "The document is locked and will remain locked if you close it without unlocking it.",
        footer: (
          <div>
            <Button
              variant="primary"
              className="me-2"
              onClick={handleCloseModal}
            >
              {common.btn_ok}
            </Button>
          </div>
        ),
      });
    } else {
      navigate("/authoring");
    }
  };

  const handleDeployment = () => {
    setIsLoading(true);
    dispatch(
      deployTreeData({
        query: { env: deploymentOptions[0].name, prd_ln: prdLn, id: treeId },
      })
    ).then(() => {
      setIsLoading(false);
      setIsUpdate(!isUpdate); // trigger to useEffect for getTreeData
      onDocumentUpdate(); // refresh the list
      handleCloseModal();
    });
  };

  const showConfirmDeploy = () => {
    showModal({
      title: "Are you sure?",
      body: `${deploymentOptions[0].msg}`,
      footer: (
        <div>
          <Button
            variant="danger"
            className="me-2"
            onClick={() => showModal(null)}
          >
            Cancel
          </Button>
          <Button variant="primary" className="me-2" onClick={handleDeployment}>
            Save
          </Button>
        </div>
      ),
    });
  };

  const handleDownload = () => {
    console.log("download");
  };
  const handleUpload = () => {
    console.log("upload");
  };

  const onSearchText = (searchTerm) => {
    setSearching(searchTerm !== "");
    console.log("text search", searchTerm);
    console.log("searching", searchTerm !== "");
    console.log("text searching", searching);

    if (searchTerm !== "") {
      // Your form submission logic here
      const payload = {
        searchtext: searchTerm,
      };

      setIsLoading(true);
      dispatch(
        getSearchTextData({
          body: payload,
          method: "POST",
          query: {
            prd_ln: prdLn,
          },
        })
      ).then(() => {
        setIsLoading(false);
        setSearching(true);
      });
    } else {
      setSearching(false);
    }
  };

  const setIcon = (nodeType, id) => {
    switch (nodeType) {
      case "question":
        return (
          <span className="svg-question me-2">
            <SVGComponent.Question />
          </span>
        );
      case "solution":
        return (
          <span className="svg-success me-2">
            <SVGComponent.Solution />
          </span>
        );
      default:
        return (
          <span className="svg-dark me-2">
            <SVGComponent.Option />
          </span>
        );
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loading isIconOnly={true} />
      ) : (
        <DocumentHeader
          documentData={documentData}
          isDocumentLocked={isDocumentLocked.current}
          deploymentOptions={deploymentOptions}
          handleDeployment={handleDeployment}
          handleDownload={handleDownload}
          handleUpload={handleUpload}
          handleDocumentIsEdit={handleDocumentIsEdit}
          handelDocumentClose={handelDocumentClose}
          docType={docType}
          btnEditDisable={btnEditDisable}
          showConfirmDeploy={showConfirmDeploy}
          onSearchText={onSearchText}
        />
      )}

      <div className="authoring-content">
        <section className={docType === "model" ? "main" : "document-content"}>
          {isLoading ? (
            <Loading isIconOnly={true} />
          ) : (
            <Fragment>
              {docType === "model" ? (
                <EmptyDocument
                  {...{
                    title: authoring.empty.model.title,
                    item_1: authoring.empty.model.list.item_1,
                    item_2: authoring.empty.model.list.item_2,
                    item_3: authoring.empty.model.list.item_3,
                    item_4: authoring.empty.model.list.item_4,
                  }}
                />
              ) : (
                <Fragment>
                  {treeData?.map((rootNode) => (
                    <TreeNode
                      key={`tree-node-${rootNode.id}-${treeId}`}
                      node={rootNode}
                      isDocumentLocked={isDocumentLocked.current}
                      handleDocumentIsEdit={handleDocumentIsEdit}
                    />
                  ))}
                </Fragment>
              )}
            </Fragment>
          )}
        </section>
        <section
          className={
            (!_isEmpty(type) && !_isEmpty(documentProperty)) || searching
              ? !isDocumentLocked.current
                ? "bg-locked document-property"
                : "document-property"
              : "d-none"
          }
        >
          <div className="container">
            <Tabs
              defaultActiveKey={searching ? "searchresults" : "properties"}
              id="uncontrolled-tab"
              className="mb-3"
            >
              <Tab eventKey="properties" title="Properties">
                <DocumentProperty
                  documentProperty={documentProperty}
                  isDocumentLocked={isDocumentLocked.current}
                />
              </Tab>
              <Tab eventKey="searchresults" title="Search esults">
                <div className="row">
                  <div col="col-xs-12">
                    <b>Questions</b>
                    <ul className="list-unstyled">
                      {searchTextData?.question?.map((item) => (
                        <li key={item.id}>
                          {setIcon(item.ques_type, item.id.toString())}{" "}
                          <Link
                            to={`/authoring/tree/${prdLn}/1/${treeId}/edit-node/${item.ques_type}/${item.start_id}/${item.id}`}
                            className="text-desc d-inline-block my-1"
                            dangerouslySetInnerHTML={renderHtmlContent(
                              item.raw_content.substring(0, 25) + "..."
                            )}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div col="col-xs-12">
                    <b>Solution</b>
                    <ul className="list-unstyled">
                      {searchTextData?.solution?.map((item) => (
                        <li key={item.id}>
                          {setIcon(item.ques_type, item.id.toString())}{" "}
                          <Link
                            to={`/authoring/tree/${prdLn}/1/${treeId}/edit-node/${item.ques_type}/${item.start_id}/${item.id}`}
                            className="text-desc d-inline-block my-1"
                            dangerouslySetInnerHTML={renderHtmlContent(
                              item.raw_content.substring(0, 25) + "..."
                            )}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div col="col-xs-12">
                    <b>Option</b>
                    <ul className="list-unstyled">
                      {searchTextData?.option?.map((item) => (
                        <li key={item.id}>
                          {setIcon(item.ques_type, item.id.toString())}{" "}
                          <Link
                            to={`/authoring/tree/${prdLn}/1/${treeId}/edit-node/${item.ques_type}/${item.start_id}/${item.id}`}
                            className="text-desc d-inline-block my-1"
                            dangerouslySetInnerHTML={renderHtmlContent(
                              item.raw_content.substring(0, 25) + "..."
                            )}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default Document;
