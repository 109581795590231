import React, { createContext, useContext, useState } from "react";
import { Modal } from "react-bootstrap";

// Create a context for the modal
const ModalContext = createContext();

// Custom hook to use the modal context
export const useModal = () => useContext(ModalContext);

// Modal component
const CustomModal = ({ title = "Modal", header, body, footer, onClose, className="" }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose && onClose(); // Call onClose if provided
  };

  return (
    <Modal show={show} onHide={handleClose} className={`${className} model-context`}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

// Modal context provider
export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState(null);

  const showModal = (props) => setModalProps(props);
  const hideModal = () => setModalProps(null); // Define hideModal function to reset modalProps

  return (
    <ModalContext.Provider value={showModal}>
      {children}
      {modalProps && <CustomModal {...modalProps} onClose={hideModal} />}
    </ModalContext.Provider>
  );
};
