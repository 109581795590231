import { Authoring, Configuration } from "../../services/api.service";
import { throwError, to } from "../../utills/utills";
import ACTIONS from "./types.actions";

var _temp = [];

export const documentList = (data) => ({
  type: ACTIONS.AUTHORING_GET_LIST,
  data,
});

export const authoringConfFilter = (data) => ({
  type: ACTIONS.AUTHORING_CONF_FILTER,
  data,
});

export const treeData = (data) => ({
  type: ACTIONS.AUTHORING_GET_TREEDATA,
  data,
});

export const documentData = (data) => ({
  type: ACTIONS.AUTHORING_GET_DOCUMENT,
  data,
});

export const copyNodeAction = (data) => ({ type: ACTIONS.COPY_NODE, data });

export function linkNodeAction(data) {
  return { type: ACTIONS.LINK_NODE, data };
}

export function getDocumentList() {
  const combineData = async (authoringRes, configurationRes) => {
    const filterByConf = configurationRes.map((conf) => {
      return {
        name: conf.name,
        key: conf.key,
        prd_ln: conf.prd_ln,
      };
    });

    const authoringList = authoringRes.map((authoring) => {
      // Find corresponding data in configurationRes based on prd_ln
      const configData = configurationRes.find(
        (config) => config.prd_ln === authoring.prd_ln
      );

      // Merge data into a new object
      if (configData) {
        return {
          ...authoring,
          conf_name: configData.name,
          key: configData.key,
          decision_tree_count: configData.decision_tree_count,
          models_list_count: configData.models_list_count,
          tags: configData.tags,
        };
      } else {
        // If no matching configuration data found, return authoring object as is
        return authoring;
      }
    });

    return {
      authoringList,
      filterByConf,
    };
  };

  return async (dispatch) => {
    try {
      const [authoringErr, authoringRes] = await to(
        Authoring.getDocumentList()
      );
      if (authoringErr) throw authoringErr;

      const [configurationErr, configurationRes] = await to(
        Configuration.getEquipmentList()
      );
      if (configurationErr) throw configurationErr;

      await combineData(authoringRes.data, configurationRes.data).then(
        (combinedData) => {
          dispatch(documentList(combinedData?.authoringList));
          dispatch(authoringConfFilter(combinedData?.filterByConf));
        }
      );
    } catch (error) {
      throwError(error);
    }
  };
}

export function getDocumentData(query) {
  return async (dispatch) => {
    try {
      const [documentErr, documentRes] = await to(
        Authoring.getDocument({
          prd_ln: query.prd_ln,
          id: query.id,
        })
      );
      if (documentErr) throw documentErr;
      dispatch(documentData(documentRes.data[0]));
    } catch (error) {
      throwError(error);
    }
  };
}

export function getTreeData(query) {
  return async (dispatch) => {
    switch (query.docType) {
      case "model":
        try {
          const [modelErr, modelRes] = await to(
            Authoring.getModelData({
              prd_ln: query.prd_ln,
              start_id: query.start_id,
              id: query.id,
            })
          );
          if (modelErr) throw modelErr;
          dispatch(treeData(modelRes.data[0])); // We are still bind with getTreeData as parent and internally calling api changed
        } catch (error) {
          throwError(error);
        }
        break;
      case "tree":
      default:
        try {
          if (query.start_id === "1") _temp = []; // Reset _temp if start_id is 1
          const [err, res] = await to(
            Authoring.getTreeData({
              prd_ln: query.prd_ln,
              start_id: query.start_id,
              id: query.id,
            })
          );

          if (err) throwError(err);

          // Check if data is received and is not empty
          if (res.data && res.data.length > 0) {
            if (query.start_id === "1") {
              // Push the first element of data array into _temp
              _temp.push(res.data[0]);
            } else {
              // Concatenate children to _temp
              _temp = [].concat(
                addChildren(res.data[0].id, res.data)(_temp[0])
              );
            }
            dispatch(treeData(_temp));
          }
        } catch (error) {
          throwError(error);
        }
        break;
    }
  };
}

export function copyNodeData(option) {

  console.log("copyNodeData option ::", option)
  return async (dispatch) => {
    try {
      const [err, res] = await to(Authoring.copyNode(option));
      if (err) throw err;
      dispatch(copyNodeAction(res));
    } catch (error) {
      throwError(error);
    }
  };
}

export function linkNodeData(option) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Authoring.linkNode(option));
      if (err) throw err;
      dispatch(linkNodeAction(res));
    } catch (error) {
      throwError(error);
    }
  };
}

const alterTree = (pred, trans) => (o) =>
  pred(o)
    ? trans(o)
    : {
        ...o,
        ...(o.children
          ? {
              children: o.children.map(alterTree(pred, trans)),
            }
          : {}),
      };

const addChildren = (targetId, children) =>
  alterTree(
    (n) => n.id === targetId,
    (node) => ({ ...node, children: [...(node.children || []), ...children] })
  );

export const createDocument = (data) => ({
  type: ACTIONS.EQUIPMENTS_DATA_PUT_POST,
  data,
});

export function createDocumentData(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Authoring.createDocument(options));
      if (err) throwError(err);
      dispatch(createDocument(res));
    } catch (error) {
      throwError(error);
    }
  };
}

export const updateTreeNode = (data) => ({
  type: ACTIONS.TREENODE_DATA_PUT_POST,
  data,
});

export function updateTreeNodeData(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Authoring.updateTreeNodeById(options));
      if (err) throwError(err);
      dispatch(updateTreeNode(res));
    } catch (error) {
      throwError(error);
    }
  };
}

export const addNode = (data) => ({ type: ACTIONS.TREENODE_ADD_POST, data });

export function addNodeData(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Authoring.addNodeById(options));
      if (err) throwError(err);
      dispatch(addNode(res));
    } catch (error) {
      throwError(error);
    }
  };
}

export const deleteNode = (data) => ({ type: ACTIONS.TREENODE_REMOVE, data });

export function deleteNodeAction(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Authoring.deleteNodeById(options));
      if (err) throwError(err);
      dispatch(deleteNode(res));
    } catch (error) {
      throwError(error);
    }
  };
}

export const lockTree = (data) => ({ type: ACTIONS.TREENODE_LOCK_ID, data });

export function lockTreeData(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Authoring.lockTreeById(options));
      if (err) throwError(err);
      dispatch(lockTree(res));
    } catch (error) {
      throwError(error);
    }
  };
}

export const deployTree = (data) => ({ type: ACTIONS.DEPLOY_TREE, data });

export function deployTreeData(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Authoring.deployTreeById(options));
      if (err) throwError(err);
      dispatch(deployTree(res));
    } catch (error) {
      throwError(error);
    }
  };
}

/* Serch node */

export const searchTextData = (data) => ({
  type: ACTIONS.SEARCH_TEXT_POST,
  data,
});

export function getSearchTextData(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Authoring.searchText(options));
      if (err) throwError(err);
      dispatch(searchTextData(res.data || {}));
    } catch (error) {
      throwError(error);
    }
  };
}
