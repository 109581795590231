import { Authoring, Configuration, Dashboard, Deployment } from "../pages";
import { ROUTES } from "../utills/constants";

const routes = [
  {
    path: ROUTES.DASHBOARD.PATH,
    exact: true,
    displayName: ROUTES.DASHBOARD.TITLE,
    component: <Dashboard />,
    isPrivate: true,
    icon: "",
    isDisabled: false,
    module: 0,
    action: visualViewport,
  },
  {
    path: ROUTES.DASHBOARD_LOG.PATH,
    exact: true,
    displayName: ROUTES.DASHBOARD_LOG.TITLE,
    component: <Dashboard />,
    isPrivate: true,
    icon: "",
    isDisabled: false,
    module: 0,
    action: visualViewport,
  },
  {
    path: ROUTES.DEPLOY.PATH,
    exact: true,
    displayName: ROUTES.DEPLOY.TITLE,
    component: <Deployment />,
    isPrivate: true,
    icon: "",
    isDisabled: false,
    module: 0,
    action: visualViewport,
  },
  {
    path: ROUTES.AUTHORING.PATH,
    exact: true,
    displayName: ROUTES.AUTHORING.TITLE,
    component: <Authoring />,
    isPrivate: true,
    icon: "",
    isDisabled: false,
    module: 0,
    action: visualViewport,
  },
  {
    path: ROUTES.AUTHORING_DOCUMENT.PATH,
    exact: true,
    displayName: ROUTES.AUTHORING_DOCUMENT.TITLE,
    component: <Authoring />,
    isPrivate: true,
    icon: "",
    isDisabled: false,
    module: 0,
    action: visualViewport,
  },
  {
    path: ROUTES.AUTHORING_DOCUMENT_EDIT.PATH,
    exact: true,
    displayName: ROUTES.AUTHORING_DOCUMENT_EDIT.TITLE,
    component: <Authoring />,
    isPrivate: true,
    icon: "",
    isDisabled: false,
    module: 0,
    action: visualViewport,
  },
  // {
  //   path: ROUTES.AUTHORING_MODEL.PATH,
  //   exact: true,
  //   displayName: ROUTES.AUTHORING_MODEL.TITLE,
  //   component: <Authoring />,
  //   isPrivate: true,
  //   icon: "",
  //   isDisabled: false,
  //   module: 0,
  //   action: visualViewport,
  // },
  // {
  //   path: ROUTES.AUTHORING_MODEL_EDIT.PATH,
  //   exact: true,
  //   displayName: ROUTES.AUTHORING_MODEL_EDIT.TITLE,
  //   component: <Authoring />,
  //   isPrivate: true,
  //   icon: "",
  //   isDisabled: false,
  //   module: 0,
  //   action: visualViewport,
  // },
  {
    path: ROUTES.CONFIGURATION.PATH,
    exact: true,
    displayName: ROUTES.CONFIGURATION.TITLE,
    component: <Configuration />,
    isPrivate: true,
    icon: "",
    isDisabled: false,
    module: 0,
    action: visualViewport,
  },
  {
    path: ROUTES.CONFIGURATION_VIEW.PATH,
    exact: true,
    displayName: ROUTES.CONFIGURATION_VIEW.TITLE,
    component: <Configuration mode="view"/>,
    isPrivate: true,
    icon: "",
    isDisabled: false,
    module: 0,
    action: visualViewport,
  },
  {
    path: ROUTES.CONFIGURATION_EDIT.PATH,
    exact: true,
    displayName: ROUTES.CONFIGURATION_EDIT.TITLE,
    component: <Configuration mode="edit" />,
    isPrivate: true,
    icon: "",
    isDisabled: false,
    module: 0,
    action: visualViewport,
  },
];

export default routes;
