// src/components/Item.js
import React from "react";
import { ListGroup, Form, Card, OverlayTrigger } from "react-bootstrap";
import { GETooltip, SVGComponent } from "..";

const DeploymentListComponent = ({ items, deployStatus, onCheckboxChange }) => {
  return (
    <ListGroup>
      {items.map((item) => (
        <ListGroup.Item key={item.id} className="p-0 mb-1 rounded">
          <Card
            className={`p-2 deployment-card ${
              item.islocked ? "locked" : deployStatus
            }`}
          >
            <Form.Check
              type="checkbox"
              disabled={item.islocked}
              label={
                <>
                  <div className="deployment">
                    <span className="icon">
                      <OverlayTrigger
                        placement="top"
                        overlay={GETooltip(
                          item.islocked
                            ? {
                                msg: `${
                                  item.doc_id === 1
                                    ? "Decision Tree"
                                    : "Model List"
                                } Locked`,
                              }
                            : item.doc_id === 1
                            ? {
                                msg: `${
                                  item.doc_id === 1
                                    ? "Decision Tree"
                                    : "Model List"
                                } Decision Tree`,
                              }
                            : {
                                msg: `${
                                  item.doc_id === 1
                                    ? "Decision Tree"
                                    : "Model List"
                                } Model List`,
                              }
                        )}
                      >
                        {item.doc_id === 1 ? (
                          <SVGComponent.DecisionTree />
                        ) : (
                          <SVGComponent.ModelList />
                        )}
                      </OverlayTrigger>
                    </span>
                    {item.name}
                  </div>
                  <div className="fs-small">
                    <span className="badge bg-dark text-white fw-lighter me-2">
                      {item.conf_name}
                    </span>
                  </div>

                  {item.islocked && (
                    <div className="fs-x-small text-danger">
                      <SVGComponent.Lock /> The document is currently locked by
                      the #{item.locked_by}
                    </div>
                  )}
                </>
              }
              onChange={() =>
                onCheckboxChange(deployStatus, {
                  prd_ln: item.prd_ln,
                  id: item.id,
                })
              }
            />
          </Card>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default DeploymentListComponent;
