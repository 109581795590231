import { Deployment } from "../../services/api.service";
import { throwError, to } from "../../utills/utills";
import ACTIONS from "./types.actions";

export const deployTrees = (data) => ({ type: ACTIONS.DEPLOY_TREES, data });

export function deployTreesData(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Deployment.deployTreeByIds(options));
      if (err) throwError(err);
      dispatch(deployTrees(res));
    } catch (error) {
      throwError(error);
    }
  };
}
