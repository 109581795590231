import _isEmpty from "lodash/isEmpty";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getEquipmentData } from "../../store/actions/configuration.actions";
import { configuration } from "../../utills/static-text";
import FormEquipment from "./FormEquipment";

import {
  FormProblemCategories,
  Loading,
  ConfigurationView,
} from "../../components";
import { Tab, Tabs } from "react-bootstrap";

const ConfigurationDetails = ({ confId, prdLn, mode }) => {
  const dispatch = useDispatch();
  const { equipmentData = null } = useSelector((state) => state.configuration);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false); // State to trigger refresh

  useEffect(() => {
    if (!_isEmpty(confId))
      dispatch(getEquipmentData({ prdLn, confId })).then(() =>
        setIsLoading(false)
      );
  }, [dispatch, confId, prdLn, refresh]);

  const refreshEquipmentData = () => {
    console.log("confId", confId)
    if (!_isEmpty(confId)) {
      setIsLoading(false);
      dispatch(getEquipmentData({ prdLn, confId })).then(() => {
        setIsLoading(false);
        setRefresh(!refresh); // Trigger refresh by toggling state
      });
    }
  };

  return (
    <div className="card sticky-top conf-property">
      <div className="card-header">
        <h5 className="card-title">
          {equipmentData.name || configuration?.configuration_card?.title}
        </h5>
        <h6 className="card-subtitle fst-italic fw-lighter text-muted text-capitaliz text-center">
          {equipmentData?.description ||
            configuration?.configuration_card?.subtitle}
        </h6>
      </div>
      <div className="card-body">
        {isLoading ? (
          <Loading isIconOnly={true} />
        ) : (
          <Fragment>
            {mode === "view" ? (
              <ConfigurationView {...equipmentData} />
            ) : (
              <Tabs
                defaultActiveKey="property"
                id="uncontrolled-tab"
                className="mb-3"
              >
                <Tab eventKey="property" title="Property">
                  <FormEquipment equipmentData={equipmentData.product} />
                </Tab>
                <Tab eventKey="problemcategories" title="Problem Categories">
                  {!isLoading && (
                    <FormProblemCategories
                      equipmentData={equipmentData.problemCategory}
                      prdLn={prdLn}
                      refreshEquipmentData={refreshEquipmentData}
                      setIsLoading={setIsLoading}
                    />
                  )}
                </Tab>
              </Tabs>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ConfigurationDetails;
