import _isEmpty from "lodash/isEmpty";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Document,
  EmptyDocument,
  AuthoringDocumentFilter,
  DocumentList,
  EmptyCard,
  Loading,
  NewDocumentForm,
} from "../../components";

import { useModal } from "../../context/ModalContext";
import { authoring, common } from "../../utills/static-text";

import { getDocumentList } from "../../store/actions/authoring.actions";
// import { Button } from "react-bootstrap";

const Authoring = () => {
  const { treeId = "" } = useParams();
  const dispatch = useDispatch();
  const showModal = useModal();
  const [filteredData, setFilteredData] = useState([]);

  const [checkDecisionTree, setCheckDecisionTree] = useState(true);
  const [checkModelList, setCheckModelList] = useState(true);

  const { documentList, filterByConf } = useSelector(
    (state) => state.authoring
  );

  useEffect(() => {
    if (_isEmpty(documentList)) dispatch(getDocumentList());

    setFilteredData(
      documentList.map((document) => {
        return { ...document };
      })
    );
  }, [dispatch, documentList]);

  useEffect(() => {
    let filtered = filteredData && documentList;
    if (checkDecisionTree || checkModelList) {
      setFilteredData(
        filtered.filter(
          (item) =>
            (checkDecisionTree && item.doc_id === 1) ||
            (checkModelList && item.doc_id === 2)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDecisionTree, checkModelList]);

  const handleFilterSelect = (filterText) => {
    setFilteredData(
      documentList.filter(
        (item) =>
          (checkDecisionTree &&
            item.doc_id === 1 &&
            item.conf_name === filterText) ||
          (checkModelList && item.doc_id === 2 && item.conf_name === filterText)
      )
    );
  };

  const handelAddDocument = (event) => {
    event.preventDefault();
    // showModal({
    //   title: common.new_document,
    //   message: <NewDocumentForm filterByConf={filterByConf} />,
    // });

    showModal({
      className: "new-document-model",
      title: (
        <>
          <i className="fa-solid fa-file"></i> {common.new_document}
        </>
      ),
      body: (
        <>
          <NewDocumentForm filterByConf={filterByConf} />
        </>
      ),
    });
  };

  const onDocumentUpdate = () => {
    dispatch(getDocumentList());
  };

  return (
    <React.Fragment>
      <div className="wrapper">
        <section id="sidebar" className="sidebar authoring">
          <AuthoringDocumentFilter
            documentList={documentList}
            filterByConf={filterByConf}
            setFilteredData={setFilteredData}
            handleFilterSelect={handleFilterSelect}
            setCheckDecisionTree={setCheckDecisionTree}
            checkDecisionTree={checkDecisionTree}
            setCheckModelList={setCheckModelList}
            checkModelList={checkModelList}
            handelAddDocument={handelAddDocument}
          />
          {!_isEmpty(filteredData) ? (
            <Fragment>
              {checkDecisionTree || checkModelList ? (
                <DocumentList data={filteredData} />
              ) : (
                <EmptyCard
                  title={authoring.emptyFilter.title}
                  body={authoring.emptyFilter.body}
                />
              )}
            </Fragment>
          ) : filteredData && filteredData?.length === 0 ? (
            <EmptyCard
              title={authoring.emptyFilter.title}
              body={authoring.emptyFilter.body}
            />
          ) : (
            <Loading isIconOnly={true} />
          )}
        </section>
        <section className="main">
          {!treeId ? (
            <EmptyDocument
              {...{
                title: authoring.empty.tree.title,
                item_1: authoring.empty.tree.list.item_1,
                item_2: authoring.empty.tree.list.item_2,
                item_3: authoring.empty.tree.list.item_3,
                item_4: authoring.empty.tree.list.item_4,
              }}
            />
          ) : (
            <Document onDocumentUpdate={onDocumentUpdate} />
          )}
        </section>
      </div>
    </React.Fragment>
  );
};

export default Authoring;
