import _isEmpty from "lodash/isEmpty";
import { Fragment, useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DocumentCard, GETooltip } from "../components";
import { getDocumentList } from "../store/actions/authoring.actions";
import { common } from "../utills/static-text";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState([]);
  const [activeCaseBase, setActiveCaseBase] = useState("ALL");

  useEffect(() => {}, [setActiveCaseBase]);

  const handelSetActive = (event) => {
    event.preventDefault();

    if (activeCaseBase !== event.target.textContent) {
      setActiveCaseBase(event.target.textContent);
    } else {
      setActiveCaseBase("ALL");
    }
  };

  const { documentList, filterByConf } = useSelector(
    (state) => state.authoring
  );

  const processData = (data) => {
    // Initialize variables
    let distinctConfNames = new Set();
    let productionCount = 0;
    let stagingCount = 0;
    let draftCount = 0;
    let duplicates = {};

    // Iterate over the data array
    data.forEach((item) => {
      // Increment total count
      distinctConfNames.add(item.conf_name);

      // Increment counts based on deploy_status
      switch (item.deploy_status) {
        case "production":
          productionCount++;
          break;
        case "staging":
          stagingCount++;
          break;
        case "draft":
        default:
          draftCount++;
          break;
      }

      // Check for duplicates
      if (duplicates[item.conf_name]) {
        duplicates[item.conf_name]++;
      } else {
        duplicates[item.conf_name] = 1;
      }
    });

    // Convert duplicates object into an array of objects
    let duplicatesArray = [];
    for (let name in duplicates) {
      if (duplicates[name] > 1) {
        duplicatesArray.push({ name, count: duplicates[name] });
      }
    }

    // Return the processed data
    return {
      distinct: distinctConfNames.size,
      total: data.length,
      production: productionCount,
      staging: stagingCount,
      draft: draftCount,
      duplicates: duplicatesArray,
    };
  };

  const processFilterData = (data, filterConfName = null) => {
    let distinctCount = 0;
    const counts = {
      total: data.length,
      production: 0,
      staging: 0,
      draft: 0,
    };
    const duplicates = {};

    data.forEach((item) => {
      if (!filterConfName || item.conf_name === filterConfName) {
        distinctCount++;

        // Count deploy_status
        if (item.deploy_status === "production") {
          counts.production++;
        } else if (item.deploy_status === "staging") {
          counts.staging++;
        } else if (item.deploy_status === "draft") {
          counts.draft++;
        }

        // Count duplicates
        if (duplicates[item.conf_name]) {
          duplicates[item.conf_name]++;
        } else {
          duplicates[item.conf_name] = 1;
        }
      }
    });

    return {
      distinct: distinctCount,
      ...counts,
      duplicates: Object.entries(duplicates).map(([name, count]) => ({
        name,
        count,
      })),
    };
  };

  const countDoocuments = (name, docId) => {
    return documentList.filter(
      (doc) => doc.conf_name === name && doc.doc_id === docId
    )?.length;
  };

  useEffect(() => {
    if (_isEmpty(documentList)) dispatch(getDocumentList());

    if (activeCaseBase === "ALL") {
      setDashboardData(processData(documentList));
    } else {
      setDashboardData(processFilterData(documentList, activeCaseBase));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, documentList, activeCaseBase]);

  return (
    <div
      className={
        activeCaseBase === "ALL"
          ? "wrapper dashboard"
          : "wrapper dashboard filtered"
      }
    >
      <div className="container mx-auto mx-5 p-5">
        {filterByConf && (
          <div className="row mb-4">
            <div className="col-lg-12">
              <h5 className="text-success">
                <i className="fa-regular fa-file"></i> Available Case base
              </h5>
              {filterByConf.map((conf) => (
                <span
                  className={`badge count position-relative me-1 fw-light p-2 mb-3 mt-2 me-3 ${
                    activeCaseBase === conf.name ? "active" : ""
                  }`}
                  key={conf.name.replace(/[^A-Z0-9]/ig, "_")}
                >
                  <button
                    className="btn btn-link m-0 p-0"
                    type="button"
                    onClick={handelSetActive}
                  >
                    {conf.name}
                  </button>
                  <OverlayTrigger
                    placement="top"
                    overlay={GETooltip({
                      msg: common.decision_tree,
                    })}
                  >
                    <span className="bg-warning tree">
                      {countDoocuments(conf.name, 1)}
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={GETooltip({
                      msg: common.model_list,
                    })}
                  >
                    <span className="bg-primary text-white model">
                      {countDoocuments(conf.name, 2)}
                    </span>
                  </OverlayTrigger>
                </span>
              ))}
            </div>
          </div>
        )}

        <div className="row mb-3">
          <div className="col-xs-4 col-lg-4 col-md-4 text-center">
            <div className="card draft cascading-admin-card">
              <div className="admin-up">
                {activeCaseBase === "ALL" ? (
                  <i className="fa fa-line-chart bg-secondary"></i>
                ) : (
                  <i className="fa bg-secondary">{activeCaseBase.charAt(0)}</i>
                )}
                <div className="data text-uppercase">
                  {activeCaseBase === "ALL" ? (
                    <p>DRAFT</p>
                  ) : (
                    <p>{activeCaseBase} in DRAFT</p>
                  )}
                  <h4>
                    <strong>{dashboardData.draft}</strong>
                  </h4>
                </div>
              </div>
              <div className="card-body">
                <div className="progress">
                  <div
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={Math.floor(
                      (dashboardData.draft * 100) /
                        (dashboardData.production +
                          dashboardData.staging +
                          dashboardData.draft)
                    )}
                    className="progress-bar bg grey"
                    role="progressbar"
                    style={{
                      width: `${Math.floor(
                        (dashboardData.draft * 100) /
                          (dashboardData.production +
                            dashboardData.staging +
                            dashboardData.draft)
                      )}%`,
                    }}
                  ></div>
                </div>
                <p className="card-text mt-3">
                  {Math.floor(
                    (dashboardData.draft * 100) /
                      (dashboardData.production +
                        dashboardData.staging +
                        dashboardData.draft)
                  ) || 0}
                  % pending review, awaiting feedback.
                </p>
              </div>
            </div>
          </div>

          <div className="col-xs-4 col-lg-4 col-md-4 text-center">
            <div className="card staging cascading-admin-card">
              <div className="admin-up">
                {activeCaseBase === "ALL" ? (
                  <i className="fa fa-line-chart bg-warning"></i>
                ) : (
                  <i className="fa bg-warning">{activeCaseBase.charAt(0)}</i>
                )}
                <div className="data text-uppercase">
                  {activeCaseBase === "ALL" ? (
                    <p>STAGING</p>
                  ) : (
                    <p>{activeCaseBase} in STAGING</p>
                  )}
                  <h4>
                    <strong>{dashboardData.staging}</strong>
                  </h4>
                </div>
              </div>
              <div className="card-body">
                <div className="progress">
                  <div
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={Math.floor(
                      (dashboardData.staging * 100) /
                        (dashboardData.production +
                          dashboardData.staging +
                          dashboardData.draft)
                    )}
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{
                      width: `${Math.floor(
                        (dashboardData.staging * 100) /
                          (dashboardData.production +
                            dashboardData.staging +
                            dashboardData.draft)
                      )}%`,
                    }}
                  ></div>
                </div>

                {dashboardData.staging === 0 && dashboardData.draft >= 0 ? (
                  <p className="card-text mt-3">
                    {dashboardData?.draft + dashboardData?.staging} drafts are
                    waiting to be push in staging.
                  </p>
                ) : (
                  <p className="card-text mt-3">
                    {Math.floor(
                      (dashboardData.staging * 100) /
                        (dashboardData.production +
                          dashboardData.staging +
                          dashboardData.draft)
                    )}
                    % document(s) waiting to be reviewed.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="col-xs-4 col-lg-4 col-md-4 text-center">
            <div className="card cascading-admin-card production">
              <div className="admin-up">
                {activeCaseBase === "ALL" ? (
                  <i className="fa fa-line-chart bg-success"></i>
                ) : (
                  <i className="fa bg-success">{activeCaseBase.charAt(0)}</i>
                )}
                <div className="data text-uppercase">
                  {activeCaseBase === "ALL" ? (
                    <p>PRODUCTION</p>
                  ) : (
                    <p>{activeCaseBase} in PRODUCTION</p>
                  )}
                  <h4>
                    <strong>{dashboardData.production}</strong>
                  </h4>
                </div>
              </div>
              <div className="card-body">
                <div className="progress">
                  <div
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={Math.floor(
                      (dashboardData.production * 100) /
                        (dashboardData.production +
                          dashboardData.staging +
                          dashboardData.draft)
                    )}
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{
                      width: `${Math.floor(
                        (dashboardData.production * 100) /
                          (dashboardData.production +
                            dashboardData.staging +
                            dashboardData.draft)
                      )}%`,
                    }}
                  ></div>
                </div>

                {dashboardData.production === 0 &&
                dashboardData.staging >= 0 ? (
                  <p className="card-text mt-3">
                    {dashboardData?.draft + dashboardData?.staging} document(s)
                    waiting to be reviewed.
                  </p>
                ) : (
                  <p className="card-text mt-3">
                    Need {dashboardData?.draft + dashboardData?.staging} to be
                    reviewed for production push.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-4">
            <div className="card bg-info text-white flex-fill">
              <div className="card-body py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h2 className="mb-2">{dashboardData.total}</h2>
                    <p className="mb-2">Total documents for all case base</p>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <i className="fa-solid fa-file fs-1"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card bg-success text-white flex-fill">
              <div className="card-body py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h2 className="mb-2">{dashboardData.distinct}</h2>
                    <p className="mb-2">
                      Total decision trees for all case base
                    </p>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <i className="fa-solid fa-file fs-1"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card bg-danger text-white flex-fill">
              <div className="card-body py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h2 className="mb-2">{dashboardData.duplicates?.length}</h2>
                    <p className="mb-2">Total model list for all case base</p>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <i className="fa-solid fa-copy fs-1"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {activeCaseBase !== "ALL" && (
          <Fragment>
            <h5 className="text-danger">
              <i className="fa-regular fa-copy"></i> Document(s) case base
            </h5>
            <div className="row">
              {documentList.filter((item) => item.conf_name === activeCaseBase)
                .length ? (
                <Fragment>
                  {documentList?.map((item) => (
                    <Fragment>
                      {item.conf_name === activeCaseBase && (
                        <div className="col-xs-4 col-lg-4 col-md-4 text-center">
                          <Link
                            to={`/authoring/tree/${item.prd_ln}/${item.start_id}/${item.id}`}
                            key={`link-${item.id}-${item.id}`}
                            className="text-decoration-none display-block"
                          >
                            <div
                              className="col-12 col-sm-12 col-xs-12 mb-2 d-flex"
                              style={{ "min-height": "169px" }}
                            >
                              <DocumentCard {...item} />
                            </div>
                          </Link>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ) : (
                <div className="col-12">No data</div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
